<template>
  <div class="responsive" style="align-content: center;">
    <sar-fields
        v-if="form.activityType === 'SAR'"
        :form="form"
        :reviewMode="true"
    ></sar-fields>
    <str-fields
        v-if="form.activityType === 'STR'"
        :form="form"
        :reviewMode="true"
    ></str-fields>

    <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>

    <v-btn
        color="secondary"
        :disabled="!isValid"
        class="button-size"
        required
        style="font-size: 22px; margin-bottom: 15px;"
        v-on:click="prev"
    >
      Edit
    </v-btn>
    <br/>

    <v-btn
        color="primary"
        :disabled="!isValid"
        class="button-size"
        required
        style="font-size: 22px"
        v-on:click="submitReport"
    >
      Submit
    </v-btn>
  </div>
</template>

<script>
import SarFields from "./SarFields.vue";
import StrFields from "./StrFields.vue";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  components: {SarFields, StrFields},
  mixins: [recaptchaMixin],
  name: "ReviewAndSubmitForm",
  props: {
    form: Object,
    step: Object,
    index: Number,
  },
  data() {
    return {
      isValid: true,
      formData: null,
    };
  },
  mounted() {
    this.formData = new FormData();
  },
  methods: {
    next() {
      this.$emit("change-step-str", this.index);
    },
    prev() {
      this.$emit("change-step-back-str", this.index);
    },
    async submitReport() {
      var formData = new FormData();

      if (this.form.activityType.length > 0)
        formData.append("activityType", this.form.activityType);
      if (this.form.merchantAccoutNumber.length > 0)
        formData.append("merchantAccount", this.form.merchantAccoutNumber);
      if (this.form.merchantName.length > 0)
        formData.append("merchantName", this.form.merchantName);
      if (this.form.transactionAmount.length > 0)
        formData.append("transactionAmount", this.form.transactionAmount);
      if (this.form.allegationType.length > 0)
        formData.append("allegationType", this.form.allegationType);
      if (this.form.allegedAccountHolderName.length > 0)
        formData.append(
            "allegedAccountHolderName",
            this.form.allegedAccountHolderName
        );
      if (this.form.allegedAccountNumber.length > 0)
        formData.append("allegedAccountNumber", this.form.allegedAccountNumber);
      if (this.form.dateOfBirth.length > 0)
        formData.append("dateOfBirth", this.form.dateOfBirth);
      if (this.form.accountHolderAddress.length > 0)
        formData.append(
            "allegedAccountHolderAddress",
            this.form.accountHolderAddress
        );
      if (this.form.photoIdType.length > 0)
        formData.append("photoIdNumber", this.form.photoIdType);
      if (this.form.photoIdNumber.length > 0)
        formData.append("photoIdNumber", this.form.photoIdNumber);
      if (this.form.image !== null && this.form.image !== undefined) {
        formData.append("image", this.form.image);
        formData.append("fileExtension", this.form.fileExtension);
        formData.append("mimeType", this.form.mimeType);
      }

      if (this.form.transactionDate.length > 0) {
        formData.append("transactionDate", this.form.transactionDate);
      }
      if (this.form.transactionType.length > 0) {
        formData.append("transactionType", this.form.transactionType);
      }
      if (this.form.reason.length > 0)
        formData.append("reason", this.form.reason);

      if (
          this.form.activityType === "STR" &&
          this.form.transactionId.length > 0
      )
        formData.append("transactionId", this.form.transactionId);

      await this.loadAndPrepareRecaptchaDetails();

      Object.keys(this.recaptcha).forEach(key => {
        formData.append(`recaptchaDetails[${key}]`, this.recaptcha[key]);
      })

      try {
        await this.$cppClientExternalServices.post(
            "suspicious/report/create",
            formData,
            null,
            true
        );

        this.$feedback.hideLoading();
        this.$feedback
            .showSuccessMessage("Report submitted successfully!")
            .then(() => {
              window.location.reload();
            });
      } catch ({response}) {
        this.$feedback.hideLoading();
        let {data} = response;
        if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          await this.$feedback.showFailed(response);
          await this.startCaptchaV2Flow();
        } else {
          await this.$feedback.showFailed(response);
        }

      }
    },
  },
};
</script>

<style scoped>
.responsive {
  width: 100%;
}

.input-field-size {
  margin-bottom: 35px;
  width: 329px;
  height: 51px;
  display: inline-block;
}

.button-size {
  height: 39px;
  width: 329px;
}

@media only screen and (max-width: 425px) {
  .responsive {
    width: 100%;
  }

  .input-field-size {
    margin-bottom: 35px;
    width: 100%;
  }

  .button-size {
    width: 329px;
  }
}

@media screen and (max-width: 768px) {
  .input-field-size {
    width: 329px !important;
  }
}


.recaptcha-v2-container {
  width: 329px !important;
  margin: auto;
  margin-bottom: 12px;
}

@media screen and (max-width: 425px) {
  .recaptcha-v2-container {
    width: 250px !important;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
</style>
