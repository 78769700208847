<template>
  <div
    class="auth-body ribbon-container"
    style="height: auto; min-height: 100vh;"
  >
    <div class="auth-el">
      <v-stepper v-model="curr" color="green" light flat class="stepper-size">
        <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n">
          <v-container>
            <card-with-logo>
              <v-form :ref="'stepForm'" v-model="step.valid" @submit.prevent class="form-width">
                <component
                  v-if="curStep == n"
                  v-bind:is="step.component"
                  :rules="step.rules"
                  :step="step"
                  :index="n"
                  :form="form"
                  v-on:change-step-str="validate"
                  v-on:change-step-back-str="stepBack"
                >
                </component>
              </v-form>
            </card-with-logo>
          </v-container>
        </v-stepper-content>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import CardWithLogo from "./components/CardWithLogo.vue";
import TermsConditionsForm from "./components/TermsConditionsForm.vue";
import ReportTypeSelectionForm from "./components/ReportTypeSelectionForm.vue";
import DetailsForm from "./components/DetailsForm.vue";
import ReviewAndSubmitForm from "./components/ReviewAndSubmitForm.vue";

export default {
  name: "StrSarReport",
  components: {
    CardWithLogo,
    TermsConditionsForm,
    ReportTypeSelectionForm,
    DetailsForm,
    ReviewAndSubmitForm,
  },
  data() {
    return {
      isValid: true,
      currentStep: 1,
      lastStep: 4,
      curStep: 0,
      valid: false,
      stepForm: [],
      curr: null,
      step: "termsAgreement",
      steps: [
        {
          name: "termsAgreement",
          valid: true,
          component: TermsConditionsForm,
        },
        {
          name: "reportTypeSelection",
          valid: true,
          component: ReportTypeSelectionForm,
        },
        {
          name: "detailsForm",
          valid: true,
          component: DetailsForm,
        },
        {
          name: "reviewAndSubmitForm",
          valid: true,
          component: ReviewAndSubmitForm,
        },
      ],
      form: {
        tos: false,
        activityType: "STR",
        merchantAccoutNumber: "",
        merchantName: "",
        allegedAccountNumber: "",
        transactionDate: "",
        transactionId: "",
        allegationType: "",
        strReason: "",
        transactionType: "",
        transactionAmount: "",
        allegedAccountHolderName: "",
        dateOfBirth: "",
        accountHolderAddress: "",
        photoIdNumber: "",
        photoIdType: "",
        reason: "",
        image: null,
        fileExtension: null,
        mimeType: null,
      },
    };
  },
  methods: {
    validate(index) {
      this.steps[index].valid = false;
      let v = this.$refs.stepForm[index].validate();
      if (v) {
        this.steps[index].valid = true;
        // continue to next
        this.curr = index + 2;
        this.curStep++;
      }
    },
    stepBack(index) {
      this.curr = index;
      this.curStep--;
    },
  },
};
</script>

<style scoped>
/* .auth-head-layout {
  display: flex;
  flex-direction: column
} */

.header-text-bold {
  font-family: Roboto;
  font-size: 45px;
  margin-top: 50px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: 0.48;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.header-text {
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.48;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

@media only screen and (max-width: 350px) {

  .stepper-size {
    width: 350px;
  }

  .form-width {
    width: 305px;
  }
}
</style>
