<template>
  <div class="responsive" style="align-content: center;">
    <div class="card-header">
      Customer/Entity Suspicious Transaction Report/Suspicious Activity Report
      <span style="color: #e2136e;">(STR/SAR)</span> Form for Merchant
    </div>
    <div class="form-options">
      Select Activity Type
      <v-radio-group v-model="form.activityType" column>
        <v-radio class="form-label" label="STR" value="STR"></v-radio>
        <v-radio class="form-label" label="SAR" value="SAR"></v-radio>
      </v-radio-group>
    </div>
    <v-btn
      color="primary"
      :disabled="!isValid"
      class="button-size"
      required
      style="font-size: 22px"
      @click="next"
    >
      Proceed
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ReportTypeSelectionForm",
  props: {
    form: Object,
    step: Object,
    index: Number,
  },
  data() {
    return {
      isValid: true,
    };
  },
  methods: {
    next() {
      this.$emit("change-step-str", this.index);
    },
    setStrForm() {
      this.$emit("change-step-str", this.index);
    },
    setSarForm() {
      this.activityType = "sar";
    },
  },
};
</script>

<style scoped>
.card-header {
  width: 522px;
  /* margin: 20.5px 0 60px; */
  font-family: Roboto;
  font-size: 29px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #707070;
}

.form-options {
  width: 299px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #95989a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label::v-deep .v-label {
  font-size: 34px;
  font-weight: 500;
}

.responsive {
  width: 100%;
}

.button-size {
  height: 39px;
  width: 329px;
}

@media only screen and (max-width: 425px) {
  .responsive {
    width: 100%;
  }

  .card-header {
    width: 360px;
    font-size: 20px;
    font-weight: 800;
  }

  .form-options {
    font-size: 25px;
    font-weight: 800;
  }
  
  .form-label::v-deep .v-label {
    font-size: 25px;
    font-weight: 800;
  }

  .button-size {
    width: 329px;
  }
}

@media only screen and (max-width: 350px) {

  .card-header {
    width: 340px;
    font-size: 20px;
    font-weight: 800;
  }

  .button-size {
    width: 300px;
  }
}
</style>
