<template>
  <div class="responsive" style="align-content: center;">
    <div class="card-header">
      <span style="color: #e2136e;">STR/SAR</span> form for Merchant
    </div>
    <div class="terms-and-conditions">
      <p><span style="color: #e2136e;">i. </span>{{ terms[0] }}</p>
      <p><span style="color: #e2136e;">ii. </span>{{ terms[1] }}</p>
      <p><span style="color: #e2136e;">iii. </span>{{ terms[2] }}</p>
      <p><span style="color: #e2136e;">iv. </span>{{ terms[3] }}</p>
      <p><span style="color: #e2136e;">v. </span>{{ terms[4] }}</p>
      <p><span style="color: #e2136e;">vi. </span>{{ terms[5] }}</p>
      <p><span style="color: #e2136e;">vii. </span>{{ terms[6] }}</p>
      <p><span style="color: #e2136e;">viii. </span>{{ terms[7] }}</p>
      <p><span style="color: #e2136e;">ix. </span>{{ terms[8] }}</p>
      <p><span style="color: #e2136e;">x. </span>{{ terms[9] }}</p>
      <p><span style="color: #e2136e;">xi. </span>{{ terms[10] }}</p>
      <p><span style="color: #e2136e;">xii. </span>{{ terms[11] }}</p>
    </div>
    <div
      style="display: flex; justify-content: center; text-align: center; font-family: Roboto;"
    >
      <v-checkbox
        class="tos-check"
        color="#ffcb15"
        :ripple="false"
        v-model="form.tos"
        :rules="tosRules"
        :false-value="false"
        :true-value="true"
        style="margin-bottom: 5px;"
      >
        <span slot="label" class="agree-to-terms-text">
          Do you agree with our
          <a style="font-weight: 500;">terms and conditions</a>
        </span>
      </v-checkbox>
    </div>
    <v-btn
      color="primary"
      :disabled="!form.tos"
      class="button-size"
      required
      style="font-size: 22px"
      v-on:click="next"
    >
      Proceed
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "TermsConditionsForm",
  props: {
    form: Object,
    step: Object,
    index: Number,
  },
  data() {
    return {
      tosRules: [
        (v) => !!v || "To proceed you must agree to terms & conditions",
      ],
      terms: [
        "STR/SAR feature will be available for all bKash Merchants who are using bKash Merchant App and having active bKash Merchant Accounts. Merchants will be able to raise STR/SAR proposals against any transaction or Activity using bKash Merchant Apps.",
        "Merchants  can  raise  STR  proposals  against  a  suspicious  transaction  conducted.  Such suspicious transactions will be relevant to all allowable transaction types relevant to each category of Merchant, which may include both P2B and B2B transactions.",
        "Merchants can raise SAR proposals against a suspected activity mentioning the suspicious bKash Account reference.  Such activity may be reported against suspicious Personal Account or Entity Account.",
        "Any  suspicious  transaction  or  activity  related  to  TF  is  also  to  be  reported  as  STR/SAR proposal in accordance with para (i), para (ii) and para (iii) above.",
        "Merchants should only use the option for submitting proposal against actual suspicious activities and transactions only.",
        "Each successful STR/SAR submission will be notified to the Merchant through the App.",
        "If there is any mandatory field(s) missing, the STR/SAR proposal(s) cannot be submitted.",
        "Successful STR/SAR request cannot be cancelled/reversed.",
        "STR/SAR  proposal  submitted  by  Merchants  will  be  dealt  subsequently  by  the  AML&CFT Department of bKash; and the Department on behalf of bKash preserves the full right of further  processing  of  the  proposal  based  on  its  merit  without  intimating  any  further communication to the initiating Merchant to adhere with the provision of “Tipping-Off”.",
        "bKash  reserves  the  right  to  store/update/upgrade/modify  STR/SAR  feature  and  the associated terms and conditions at its sole discretion.",
        "As per “Safe Harbor Provision”, disclosure of STR/SAR by a reporting Person/entity shall not constitute a breach of any restriction on disclosure of information imposed by contract or by any legislative, regulatory or administrative provision, and shall not involve the obliged entity or its directors or employees in liability of any kind even in circumstances where they were not precisely aware of the underlying criminal activity and regardless of whether the illegal activity actually occurred.",
        "In section (28) of MLPA, 2012 provides the safe harbor for persons submitting suspicious reports. As per Section 28 of MLPA, no suit or prosecution or administrative measures or any other legal proceedings shall lie against any reporting organization or its Board of Directors or any of its officers or staffs for anything which is done in good faith under this Act or Rules made thereunder for which any person is or likely to be affected.",
      ],
    };
  },
  methods: {
    next() {
      this.$emit("change-step-str", this.index);
    },
  },
};
</script>

<style scoped>
.card-header {
  font-family: Roboto;
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #707070;
  margin-bottom: 25px;
}

.terms-and-conditions {
  width: 438px;
  height: 438px;
  overflow: scroll;
  padding: 10px 30px 11px 31px;
  border: solid 2px #95989a;
  margin-left: auto;
  margin-right: auto;
}

.agree-to-terms-text {
  font-size: 18px;
}

.responsive {
  width: 100%;
}

.button-size {
  height: 39px;
  width: 329px;
}

@media only screen and (max-width: 425px) {
  .card-header {
    font-size: 20px;
    font-weight: 800;
  }

  .responsive {
    width: 100%;
  }

  .terms-and-conditions {
    width: 340px;
    height: 310px;
  }

  .agree-to-terms-text {
    font-size: 15px;
  }

  .button-size {
    width: 329px;
  }
}

@media only screen and (max-width: 350px) {

  .terms-and-conditions {
    width: 300px;
    height: 300px;
  }

  .button-size {
    width: 300px;
  }

  .agree-to-terms-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .input-field-size {
    width: 329px !important;
  }
}
</style>
