<template>
  <div class="responsive" style="align-content: center;">
    <div class="card-header">
      <span style="color: #e2136e;">SAR</span> form for Merchant
    </div>
    <div class="sub-title">
      Merchant Details
    </div>
    <div
      style="border: 1px solid #e2136e; width: 100%; box-shadow: 0px 1px 6px #888888;"
    ></div>
    <v-container style="margin-bottom: 50px;">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.merchantAccoutNumber"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            :rules="accountNumberRules"
            placeholder="Your Merchant Account Number"
            type="number"
            maxlength="11"
            required
            outlined
            :disabled="reviewMode"
          >
            <template #label>
              Merchant Account Number
              <span style="color: #e2136e"><strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.merchantName"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            :rules="nameRules"
            label="Merchant Name"
            placeholder="Your Merchant Name"
            type="text"
            maxlength="35"
            required
            outlined
            :disabled="reviewMode"
          >
            <template #label>
              Merchant Name
              <span style="color: #e2136e"><strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div class="sub-title">
      Transaction Details
    </div>
    <div
      style="border: 1px solid #e2136e; width: 100%; box-shadow: 0px 1px 6px #888888;"
    ></div>
    <v-container style="margin-bottom: 50px;">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <v-menu
            ref="tmenu"
            v-model="tMenu"
            :close-on-content-click="false"
            :return-value.sync="form.transactionDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="reviewMode"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.transactionDate"
                class="inputNumber input-field-size"
                style="margin-top: 55px; margin-bottom: 0px;"
                placeholder="Transaction Date"
                append-icon="mdi-calendar"
                :rules="transactionDateRules"
                required
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="reviewMode"
              >
                <template #label>
                  Transaction Date
                  <span style="color: #e2136e"><strong>* </strong></span>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="form.transactionDate"
              no-title
              scrollable
              :max="new Date(Date.now())
                  .toISOString()
                  .substr(0, 10)"
              :disabled="reviewMode"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="tMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.tmenu.save(form.transactionDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-model="form.transactionType"
            :items="transactionTypes"
            label="Transaction Type"
            placeholder="Select Transaction Type"
            outlined
            :disabled="reviewMode"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.transactionAmount"
            class="inputNumber input-field-size"
            :rules="transactionAmountRules"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Transaction Amount"
            placeholder="Transaction Amount"
            type="number"
            maxlength="8"
            required
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
          <v-select
            v-model="form.allegationType"
            :items="allegationTypes"
            label="Allegation Type"
            placeholder="Select Allegation Type"
            :rules="allegationTypeRules"
            outlined
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            :disabled="reviewMode"
          >
            <template #label>
              Allegation Type
              <span style="color: #e2136e"><strong>* </strong></span>
            </template>
          </v-select>
          <v-text-field
            v-model="form.reason"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Reason of SAR"
            placeholder="Reason of SAR if you select ‘’Other’’"
            type="text"
            maxlength="200"
            required
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div class="sub-title">
      Alleged Account Details
    </div>
    <div
      style="border: 1px solid #e2136e; width: 100%; box-shadow: 0px 1px 6px #888888;"
    ></div>
    <v-container style="margin-bottom: 50px;">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.allegedAccountHolderName"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Alleged Account Holder's Name"
            placeholder="Alleged Account Holder's Name"
            type="text"
            required
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
          <v-menu
            ref="bmenu"
            v-model="bMenu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="reviewMode"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.dateOfBirth"
                class="inputNumber input-field-size"
                style="margin-top: 55px; margin-bottom: 0px;"
                label="Date of Birth"
                placeholder="Date of Birth (DD/MM/YYYY)"
                append-icon="mdi-calendar"
                required
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="reviewMode"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.dateOfBirth"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
              :disabled="reviewMode"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="bMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.bmenu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-model="form.allegedAccountNumber"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            :rules="accountNumberRules"
            placeholder="Account Number of Alleged"
            type="number"
            required
            outlined
            :disabled="reviewMode"
          >
            <template #label>
              Account Number of Alleged
              <span style="color: #e2136e"><strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.accountHolderAddress"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Alleged Account Holder's Address"
            placeholder="Alleged Account Holder's Address"
            type="text"
            maxlength="45"
            required
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
          <v-text-field
            v-model="form.photoIdType"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Photo ID Type"
            placeholder="Photo ID Type"
            type="text"
            maxlength="25"
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
          <v-text-field
            v-model="form.photoIdNumber"
            class="inputNumber input-field-size"
            style="margin-top: 55px; margin-bottom: 0px;"
            label="Photo ID Number"
            placeholder="Photo ID Number"
            type="text"
            maxlength="25"
            outlined
            :disabled="reviewMode"
          >
          </v-text-field>
          <v-file-input
            ref="fileSelector"
            v-model="form.image"
            :rules="imageRules"
            accept="image/png, image/jpeg, image/bmp"
            label="Account Holder’s Picture"
            placeholder="Image format: png/jpg/jpeg"
            style="margin-top: 55px; margin-bottom: 0px;"
            class="input-field-size"
            append-icon="mdi-camera"
            prepend-icon=""
            outlined
            show-size
            @change="previewImage"
            @click:append="manuallyOpenFileSelector"
            :disabled="reviewMode"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "sarFields",
  props: {
    form: Object,
    reviewMode: Boolean,
  },
  data() {
    return {
      isValid: true,
      accountNumberRules: [
        (v) => !!v || "Cannot be blank!",
        (v) => (v && v.length === 11) || "Number must have 11 digit",
      ],
      nameRules: [(v) => !!v || "Cannot be blank!"],
      transactionDateRules: [(v) => !!v || "Cannot be blank!"],
      transactionAmountRules: [
        (v) => (v.length === 0 || v > 0) || 'Amount must be greater than 0',
      ],
      allegationTypeRules: [(v) => !!v || "Cannot be blank!"],
      imageRules: [
        v => !v || v.size < 50000 || 'Avatar size should be less than 50 KB!',
      ],
      allegationTypes: [],
      transactionTypes: [],
      bMenu: false,
      tMenu: false,
      activePicker: "YEAR",
    };
  },
  mounted() {
    this.getTransactionTypes();
    this.getAllegationTypes();
  },
  watch: {
    bMenu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    manuallyOpenFileSelector() {
      let el = this.$refs.fileSelector.$refs.input;
      el.click();
    },
    previewImage() {
      if (this.form.image !== null) {
        this.form.mimeType = this.form.image.type;
        this.form.fileExtension = this.form.image.name
          .split(".")
          .pop()
          .toLowerCase();
      } else {
        this.form.mimeType = null;
        this.form.fileExtension = null;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    async getAllegationTypes() {
      this.$feedback.showLoading();
      try {
        let {
          data,
        } = await this.$cppClientExternalServices.post(
          "suspicious/report/allegation-type/list",
          { activityType: "SAR" }
        );
        this.allegationTypes = data.allegationTypes;
        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      }
    },
    async getTransactionTypes() {
      this.$feedback.showLoading();
      try {
        let { data } = await this.$cppClientExternalServices.post(
          "suspicious/report/transaction-type/list",
          {}
        );
        this.transactionTypes = data.transactionTypeList;

        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      }
    },
  },
};
</script>

<style scoped>
.card-header {
  /* margin: 20.5px 0 60px; */
  font-family: Roboto;
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #707070;
  display: inline-block;
}

.sub-title {
  font-size: 25px;
  font-weight: 500;
  text-align: left;
  color: #707070;
}

.form-options {
  width: 299px;
  margin: 60px 111px 20px 112px;
  font-family: Roboto;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #95989a;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label::v-deep .v-label {
  font-size: 34px;
  font-weight: 500;
}

.responsive {
  width: 100%;
}

.input-field-size {
  margin-bottom: 35px;
  width: 329px;
  height: 51px;
  display: inline-block;
}

@media only screen and (max-width: 425px) {
  .responsive {
    width: 100%;
  }

  .card-header {
    font-size: 20px;
    font-weight: 800;
  }

  .sub-title {
    font-size: 20px;
  }

  .input-field-size {
    margin-bottom: 35px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .input-field-size {
    width: 329px !important;
  }
}
</style>
