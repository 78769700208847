<template>
  <div class="responsive" style="align-content: center;">
    <sar-fields
      v-if="form.activityType === 'SAR'"
      :form="form"
      :reviewMode="false"
    ></sar-fields>
    <str-fields
      v-if="form.activityType === 'STR'"
      :form="form"
      :reviewMode="false"
    ></str-fields>
    <v-btn
      color="primary"
      :disabled="!step.valid"
      class="button-size"
      required
      style="font-size: 22px"
      v-on:click="next"
    >
      Review
    </v-btn>
  </div>
</template>

<script>
import SarFields from "./SarFields.vue";
import StrFields from "./StrFields.vue";
export default {
  components: { SarFields, StrFields },
  name: "DetailsForm",
  props: {
    form: Object,
    step: Object,
    index: Number,
  },
  methods: {
    next() {
      this.$emit("change-step-str", this.index);
    },
  },
};
</script>

<style scoped>
.responsive {
  width: 100%;
}

.button-size {
  height: 39px;
  width: 329px;
}

.form-style {
  margin-bottom: 25px;
}

@media only screen and (max-width: 425px) {
  .responsive {
    width: 100%;
  }

  .button-size {
    width: 329px;
  }

  .form-style {
    display: inline-block;
    width: 261px;
  }
}

@media only screen and (max-width: 350px) {

  .responsive {
    width: 100%;
  }

  .input-field-size {
    width: 300px;
  }

  .button-size {
    width: 300px;
  }
}
</style>
