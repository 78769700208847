<template>
  <v-card
    :min-width="cardMinWidth"
    :min-height="cardMinHeight"
    class="card-border"
  >
    <v-container style="padding-top: 25px;">
      <img
        src="../../../assets/image/bKash-logo.svg"
        @click="$router.push({ name: 'landing' })"
        class="bkash-icon"
        style="cursor: pointer;"
      />
      <v-col class="slot-style">
        <slot />
      </v-col>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "CardWithLogo",
  computed: {
    cardMinWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return '100%';
        default:
          return 595;
      }
    },
    cardMinHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 450;
        default:
          return 695;
      }
    },
  },
};
</script>

<style scoped>
.auth-head-layout {
  display: flex;
  flex-direction: column;
}

.header-text {
  font-family: Roboto !important;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

.card-border {
  border: 1px solid #95989a;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.slot-style {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 425px) {
  .card-border {
    border: none;
    box-shadow: none !important;
  }

  .header-text {
    font-size: 22px;
  }
}
</style>
